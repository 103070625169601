<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable :max-width="maxWidth" persistent>
      <v-card class="transaction-history-bg-color">
        <v-card-title class="text-center"
          ><div style="width:100%;">
            <span>{{ title }}</span>
            <v-icon class="float-right" color="default" @click="closeDialog">mdi-close-circle</v-icon>
          </div>
        </v-card-title>
        <v-card-text :style="`max-height: ${maxHeight};`">
          <v-row class="d-flex justify-center caption ">
            <v-col cols="12">
              <v-sheet class="my-4 pa-2 wl-header" elavation="2">
                <v-row class="transaction-history-title">
                  <v-col cols="3" class="text-center">ID</v-col>
                  <v-col class="text-center">TYPE</v-col>
                  <v-col class="text-center">AMOUNT</v-col>
                  <v-col class="text-center">REQUEST DATE</v-col>
                  <v-col class="text-center">STATUS</v-col>
                </v-row>
              </v-sheet>
              <!-- <v-divider></v-divider> -->
              <v-sheet
                class="mb-2 pa-2 transaction-history-bg-color "
                elavation="2"
                v-for="(item, rowIndex) in items"
                :key="`item-row-${rowIndex}`"
              >
                <v-row class="pa-2 d-flex align-center">
                  <v-col cols="3" class="text-center">{{ item._id }}</v-col>
                  <v-col class="text-center" v-if="showType">{{ item.type | uppercase }}</v-col>
                  <v-col class="text-center">{{
                    item.amount | currency("&#8369;", 2, { spaceBetweenAmountAndSymbol: true })
                  }}</v-col>
                  <v-col class="text-center">
                    {{ formatDate(item.dateCreated) }}
                  </v-col>
                  <v-col class="text-center">
                    <span :class="`${getStatusColor(item.status)}`">{{ item.status | uppercase }}</span>
                  </v-col>
                </v-row>
              </v-sheet>

              <v-sheet class="mb-2 pa-2" elavation="2" v-if="items.length == 0">
                <v-row class="transaction-history-bg-color">
                  <v-col cols="12" class="text-center">No transactions found.</v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Close
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    maxWidth: {
      default: "100%",
    },
    maxHeight: {
      default: "100%",
    },
    showType: {
      default: false,
    },
  },
  data() {
    return {
      dialogm1: "",
    };
  },
  methods: {
    formatDate: function(date) {
      return this.$moment(date).format("YYYY-MM-DD H:mm");
    },
    closeDialog: function() {
      this.$emit("closeDialog", true);
    },
    getStatusColor: function(status) {
      return status == "pending" ? "orange--text" : status == "approved" ? "green--text" : "red--text";
    },
  },
};
</script>
<style scoped>
.transaction-history-title {
  background: linear-gradient(rgb(51, 200, 181) 0%, rgb(44, 171, 98) 100%);
  border-radius: 10px;
}
.transaction-history-bg-color {
  background: #1b2221;
  border-radius: 16px;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #333649 #16181f;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  left: 100px;
}

*::-webkit-scrollbar-track {
  background: #16181f;
}

*::-webkit-scrollbar-thumb {
  background-color: #333649;
  border-radius: 10px;
  border: 2px solid#16181f;
}
</style>
