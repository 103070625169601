<template>
  <div>
    <v-app-bar class="header-color" clipped-left>


      <div class="logo-container d-flex align-center mr-">
        <img src="../../assets/logo.png" class="mr-2" />
        <v-toolbar-title class="d-none d-md-block">FLIPMASTER.BET</v-toolbar-title>
      </div>

      <!--  <div class="header-links mx-auto ml-md-12">
    <router-link to="/coinflip" class="mr-3" :class="`${activeLink.includes('coinflip') ? 'active' : ''}`">
      Coinflip
    </router-link>
    <router-link to="/wheel" class="mr-3" :class="`${activeLink.includes('wheel') ? 'active' : ''}`">WHEEL</router-link>
  </div>-->

      <v-spacer></v-spacer>

      <div v-show="!$vuetify.breakpoint.xsOnly" class="mr-2">
        <CreateGame></CreateGame>
      </div>

      <!-- desktop -->
      <div v-if="!!user" class="d-none d-md-block mr-2">
        <button class="chip-button  no-uppercase caption">
          <v-img width="25" height="25" :src="logo" class="mr-2"></v-img>
          <span>{{ wallet || 0 | currency("") }} </span>
        </button>
      </div>

      <div class="ml-1 d-none d-md-block">
        <v-btn @click="showBetHistory = true" link icon small class="d-flex items-center justify-center">
          <v-img :src="require('../../assets/images/history.svg')" alt=""></v-img>
        </v-btn>
      </div>


      <!--  <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon><v-icon>mdi-cog</v-icon></v-btn>
      </template>

      <v-card width="300">
        <v-list class="profile-dialog-bg-color">
          <v-list-item>
            <v-list-item-avatar>
              <avataaars :circleColor="avatarOptions.circleColor" :accessoriesType="avatarOptions.accessoriesType"
                :clotheType="avatarOptions.clotheType" :clotheColor="avatarOptions.clotheColor"
                :eyebrowType="avatarOptions.eyebrowType" :eyeType="avatarOptions.eyeType"
                :facialHairColor="avatarOptions.facialHairColor" :facialHairType="avatarOptions.facialHairType"
                :hairColor="avatarOptions.hairColor" :mouthType="avatarOptions.mouthType"
                :skinColor="avatarOptions.skinColor" :topType="avatarOptions.topType" :topColor="avatarOptions.topColor"
                v-if="user.avatar_type == 'avatar'"></avataaars>
              <v-avatar v-if="user.avatar_type == 'photo'">
                <img :src="user.display_photo.thumb" />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ user.full_name }}</v-list-item-title>
              <v-list-item-subtitle class="user-email-txt-color">{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="menu = false">
                <v-icon color="default">mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list class="profile-dialog-bg-color">
          <v-list-item @click="() => { }">
            <v-list-item-action>
              <v-img width="30" height="25" :src="logo" class="mr-2"></v-img>
            </v-list-item-action>
            <v-list-item-subtitle>
              <span class="font-weight-bold">{{ wallet || 0 | currency("") }} </span></v-list-item-subtitle>
          </v-list-item>

          <v-list-item @click="showBetHistoryDialog">
            <v-list-item-action>
              <v-icon color="green lighten-1">mdi-poker-chip</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle> Bet History</v-list-item-subtitle>
          </v-list-item>
          <v-list-item @click="showTransactionHistoryDialog">
            <v-list-item-action>
              <v-icon color="green lighten-1">mdi-history</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>Transactions</v-list-item-subtitle>
          </v-list-item>
          <v-list-item @click="() => {
              $router.push('leaderboards');
            }
            ">
            <v-list-item-action>
              <v-icon color="green lighten-1">mdi-chevron-triple-up</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>Leaderboards</v-list-item-subtitle>
          </v-list-item>

        </v-list>
      </v-card>
    </v-menu> 

    <v-btn icon color="green" @click="toggleChat">
      <v-icon>mdi-forum</v-icon>
    </v-btn>
  </div> -->

      <!-- mobile -->
      <div v-if="!!user" class="d-md-none d-flex items-center">
        <v-chip label class="ma-2 chip-button">
          <v-img width="25" height="25" :src="logo" class="mr-2"></v-img>
          <span class="font-weight-bold">{{ wallet || 0 | currency("") }} </span>
        </v-chip>

        <div class="ml-1 mt-2">
          <v-btn @click="showBetHistory = true" link icon small class="d-flex items-center justify-center">
            <v-img :src="require('../../assets/images/history.svg')" alt=""></v-img>
          </v-btn>
        </div>
        <!-- <v-row>
      <v-btn icon color="green" @click="toggleChat">
        <v-icon>mdi-forum-outline</v-icon>
      </v-btn> 

      <v-menu v-model="menu">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-menu</v-icon>
        </template>
      </v-menu>
    </v-row> -->
      </div>
    </v-app-bar>
    <TransactionHistory :dialog="showTransactionHistory" @hideDialog="showTransactionHistory = false" />
    <BetHistory :dialog="showBetHistory" @hideDialog="showBetHistory = false" />
  </div>
</template>

<script>





// import Avataaars from "vuejs-avataaars";
import logo from "../../assets/logo.png";
import { mapState, mapActions } from "vuex";
import BetHistory from "../menu/BetHistory";
import TransactionHistory from "../menu/TransactionHistory";
import CreateGame from "../coinflip/CreateGame.vue";
export default {
  name: `TheHeader`,
  components: { BetHistory, TransactionHistory, CreateGame },
  data: () => ({
    logo: logo,
    menu: false,
    showBetHistory: false,
    showTransactionHistory: false,
    showRequestCashout: false,
    showProfile: false,
    avatarOptions: {
      circleColor: "#a7c5b4",
      accessoriesType: "Blank",
      clotheType: "ShirtVNeck",
      clotheColor: "White",
      eyebrowType: "DefaultNatural",
      eyeType: "Default",
      facialHairColor: "Black",
      facialHairType: "Blank",
      hairColor: "Black",
      mouthType: "Default",
      skinColor: "Pale",
      topType: "NoHair",
      topColor: "Black",
    },
  }),
  computed: {
    ...mapState("auth", ["user", "wallet", "hasNewMessage", "showChatBox"]),
    activeLink: function () {
      return this.$route.path;
    },
  },

  methods: {
    ...mapActions("auth", ["setUser"]),

    toggleChat: function () {
      this.$store.dispatch("auth/setToggleChatBox", !this.showChatBox);
    },

    showBetHistoryDialog: function () {
      this.showBetHistory = true;
    },

    showTransactionHistoryDialog: function () {
      this.showTransactionHistory = true;
    },

    showRequestCashoutDialog: function () {
      this.showRequestCashout = true;
    },

    // showProfileDialog: function () {
    //   this.showProfile = true;
    // },
  },

  mounted() {
    this.$store.dispatch("auth/setToggleChatBox", false);
    if (!!this.user.avatar_options == true) {
      this.avatarOptions = this.user.avatar_options;
    }
  },
};
</script>

<style lang="scss">
.user-email-txt-color {
  color: #455C59 !important;

}

.profile-dialog-bg-color {
  background: #1F2C28 !important;
}

.chip-button {
  background: linear-gradient(180deg, #33c8b5 0%, #2cab62 100%) !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5.1px 13px;
  margin-left: 10px;
}

.chip-button span {
  font-weight: 700;
  font-size: 14px;
}

.header-color {
  background: #1b2221 !important;
}

.logo {
  display: inline;
  margin-right: 10px;
}

.site-name {
  display: inline-block;
  vertical-align: top;
  margin-top: 8px !important;
  margin-left: 5px;
}

// .second-header {
// 	background-color: #006d24;
// 	padding: 10px;
// }

.header-tabs .v-tab {
  margin-left: 0px !important;
  border-top: 1px solid #497b5b;
}

.layout-header {
  border-radius: 0px !important;
}

.header-links a,
.chat-link {
  text-decoration: none;
  color: white !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.header-links a.active,
.chat-link.active {
  color: #2cab62 !important;
  font-weight: bold;
}
</style>
