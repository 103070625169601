<template>
	<span>
		<TransactionListDialog
			:dialog="dialog"
			@closeDialog="hideDialog"
			title="Transaction History"
			max-height="500px"
			max-width="950px"
			:items="transactions"
			show-type="true"
		/>
	</span>
</template>
<script>
	import { mapState } from 'vuex';
	import TransactionListDialog from '../dialogs/TransactionListDialog';
	export default {
		components: {
			TransactionListDialog
		},
		props: ['dialog'],
		data: () => ({
			showDialogHistory: false
		}),
		computed: {
			...mapState('auth', ['user']),
			...mapState('transaction', ['transactions'])
		},
		watch: {
			dialog: function(newValue) {
				if (newValue) {
					this.showDialog();
				}
			}
		},
		methods: {
			showDialog: function() {
				this.getTransactionHistory();
			},
			hideDialog: function() {
				this.showDialogHistory = false;
				this.$emit('hideDialog');
			},
			getTransactionHistory: async function() {
				const payload = {
					playerId: this.user.id
				};
				await this.$store.dispatch('transaction/getPlayerTransactions', payload);
				this.showDialogHistory = true;
			}
		}
	};
</script>
