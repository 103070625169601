<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable :max-width="maxWidth">
      <v-card class="bet-history-bg-color">
        <v-card-title class="text-center"
          ><div style="width:100%;">
            <span>Preview</span>
            <v-icon class="float-right" color="default" @click="closeDialog">mdi-close-circle</v-icon>
          </div>
        </v-card-title>
        <v-card-text :style="`max-height: ${maxHeight};`" class="pa-5">
          <game-card :coinflip="coinflip" :is-previous="true" v-if="!!coinflip"></game-card>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GameCard from "../coinflip/GameCard";
export default {
  components: {
    GameCard,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      default: "100%",
    },
    maxHeight: {
      default: "100%",
    },
    showType: {
      default: false,
    },
    coinflip: {
      default: null,
    },
  },
  data() {
    return {
      dialogm1: "",
    };
  },
  methods: {
    closeDialog: function() {
      this.$emit("closeDialog", true);
    },
  },
};
</script>
<style scoped>
.bet-history-bg-color {
  background: #1b2221;
  border-radius: 16px;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #333649 #16181f;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  left: 100px;
}

*::-webkit-scrollbar-track {
  background: #16181f;
}

*::-webkit-scrollbar-thumb {
  background-color: #333649;
  border-radius: 10px;
  border: 2px solid#16181f;
}
</style>
