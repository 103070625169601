<template>
	<div>
		<BetListDialog :dialog="dialog" @closeDialog="hideDialog" title="Bet History" :max-height="500" max-width="950px"
			show-type="true" />
	</div>
</template>
<script>
import { mapState } from 'vuex';
import BetListDialog from '../dialogs/BetListDialog.vue';
export default {
	components: {
		BetListDialog
	},
	props: ['dialog'],

	computed: {
		...mapState('auth', ['user']),
	},

	methods: {
		hideDialog: function () {
			this.$emit('hideDialog');
		},

	}
};
</script>
