<template>
  <v-app dark>
    <div v-if="validation.socket_connected && validation.init_info">
      <v-row no-gutters class="margin-all ">
        <!-- <v-col
          :md="showChatBox ? 8 : 12"
          :lg="showChatBox ? 10 : 12"
          :xl="showChatBox ? 10 : 12"
          :cols="showChatBox ? 12 : 12"
        > -->
        <v-col>
          <v-container fluid class="pa-0">
            <nav v-if="showHeader">
              <TheHeader />
            </nav>
            <main class="LayoutDefault__main">
              <v-main>
                <div class="pa-0">
                  <v-row no-gutters>
                    <v-col>
                      <div :class="showChat ? 'router-right-container' : ''" class="router-fill-height ma-0"
                        :id="showChat ? 'router-right-container' : ''">
                        <router-view class="pa-xs-0 pa-sm-0 pa-md-5 pa-lg-5 pa-xl-5"></router-view>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div></div>
              </v-main>
            </main>
          </v-container>
        </v-col>
        <!-- <v-col
          :md="showChatBox ? 4 : 0"
          :lg="showChatBox ? 2 : 0"
          :xl="showChatBox ? 2 : 0"
          :cols="showChatBox ? 0 : 0"
          class="chat-box-container"
          :class="!showChatBox ? 'd-none' : ''"
        >
          <ChatBox @hideChat="hideChat" />
        </v-col> -->
      </v-row>
    </div>
    <div v-else>
      <v-container class="d-flex align-center  w-100 ">
        <v-card class=" mx-4 w-100 ">
          <v-card-title>Loading...</v-card-title>
        </v-card>
      </v-container>
    </div>

    <v-dialog v-model="dialog.show" width="500">
      <v-card>
        <v-card-title class="headline">Error</v-card-title>
        <v-card-text>{{ dialog.text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog.show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import io from "socket.io-client";
import TheHeader from "../components/layout/TheHeader";
// import ChatBox from "../components/chat/ChatBox";
import { mapState } from "vuex";

window.socket = null;
window.provider_socket = null;

export default {
  name: `LayoutDefault`,
  components: {
    TheHeader,
  },
  data: () => ({
    dialog: {
      show: false,
      text: "",
    },
    validation: {
      socket_connected: false,
      init_info: false,
    },
    socket: null,
    provider_socket: null,
  }),
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("auth", ["showChatBox"]),
    showHeader: function () {
      return !!this.$router.currentRoute.meta.showHeader;
    },
    showChat: function () {
      return !!this.$router.currentRoute.meta.showChat;
    },
    showFooter: function () {
      return !!this.$router.currentRoute.meta.showFooter;
    },
  },
  methods: {
    hideChat: function () {
      this.$store.dispatch("auth/setToggleChatBox", !this.showChatBox);
    },
    initProviderSocket: function () {
      this.provider_socket = io(process.env.VUE_APP_PROVIDER_SOCKET_API, {
        path: process.env.VUE_APP_PROVIDER_SOCKET_PATH,
        query: { key: this.$route.params.key },
      });
      window.provider_socket = this.provider_socket;
      this.provider_socket.on("INIT_ERROR", (payload) => {
        window.location.href = "about:blank";
        alert(payload.message);
      });

      this.provider_socket.on("INIT_SUCCESS", async (user) => {
        this.$store.dispatch("auth/setUser", user);
        this.provider_socket.emit("INIT_GAME", this.$route.params.key);
      });

      this.provider_socket.on("INIT_GAME", async () => {
        this.validation.init_info = true;
      });

      this.provider_socket.on("GAME_ACTION", async (payload) => {
        if (payload.action == "error") {
          this.dialog.show = true;
          this.dialog.text = payload.message;
        }
        this.$store.dispatch("auth/setWallet", payload.balance || 0);
      });
    },
    initLobbySocket: function () {
      this.socket = io(process.env.VUE_APP_LOBBY_SOCKET_API, {
        path: process.env.VUE_APP_LOBBY_SOCKET_PATH,
        query: { key: this.$route.params.key },
      });
      window.socket = this.socket;
      this.socket.on("connect", () => {
        this.validation.socket_connected = true;
      });
      this.socket.on("INSERT_COINFLIP_LOBBY", (lobby) => {

        this.$store.dispatch("lobby/insertNewCoinflipLobby", [lobby]);
      });

      this.socket.on("INIT_SUCCESS", async () => {
        console.log("INIT_GAME");
      });

      this.socket.on("INIT_ERROR", async (error) => {
        console.log("INIT_ERROR", error);
      });

      this.socket.on("GAME_ACTION", async (payload) => {
        this.$store.dispatch("auth/setWallet", payload.balance || 0);
      });

      this.socket.on("SETTLED_COINFLIP_LOBBY", (lobby) => {
        //if you own it, then update your own lobby
        this.$store.dispatch("lobby/updateCoinflipLobby", lobby);
      });

      this.socket.on("CANCELLED_COINFLIP_LOBBY", (lobby) => {
        //if you own it, then update your own lobby
        this.$store.dispatch("lobby/updateCoinflipLobby", lobby);
      });
    },
  },
  mounted() {
    this.initLobbySocket();
    this.initProviderSocket();
  },
  created() {
    let pageTitle = "FLIPMASTER.BET";
    pageTitle += this.$route.name ? ` | ${this.$route.name}` : "";
    document.title = pageTitle;
  },
};
</script>

<style lang="scss">
.row {
  margin: 0px 0px 0px 0px;
}

.alert-multi-line {
  white-space: pre-line;
}

.fill-height {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 100vh;
}

.router-fill-height {
  min-height: calc(100vh - 56px) !important;
}

.router-right-container {
  // padding-left: var(--site-chat-width) !important;
}

.main-wrapper {
  padding: -5px !important;
}

.chat-box-container {
  position: fixed;
  z-index: 99 !important;
  top: 0px;
  height: 100%;
  right: 0px;
  bottom: 0px;
  background-color: #272727 !important;
}
</style>
