<template>
  <div>

    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable :max-width="maxWidth"
      class="pa-0 px-0 ma-0" style="width:100%;" persistent>
      <v-card class="bet-history-bg-color px-0 pa-0" height="600" width="500" style="width:100%;">
        <!-- <v-card-title class="text-center"
          ><div class="mx-auto">
            <span>Bet History</span>

            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1 v-icon notranslate float-right v-icon--link material-icons theme--dark error--text"
              text
              @click="closeDialog"
            >
              x
            </v-btn>
          </div></v-card-title
        > -->
        <v-card-title class="text-center">
          <div style="width:100%;">
            <span>Bet History</span>
            <v-icon class="float-right" color="default" @click="closeDialog">mdi-close-circle</v-icon>
          </div>
        </v-card-title>
        <v-card-text :style="`max-height: ${maxHeight + ($vuetify.breakpoint.xsOnly ? 50 : 0)}px;`"
          class="mobile-padding-left-and-right">
          <v-row class="d-flex justify-center caption">
            <v-col cols="12" class="mobile-padding-left-and-right">
              <v-sheet class="my-4 pa-2 wl-header background-remove" elavation="2">
                <v-row class="bet-history-title">
                  <v-col :cols="`${$vuetify.breakpoint.xsOnly ? '3' : '4'}`" class="text-center">ID</v-col>
                  <v-col class="text-center" v-if="showType">GAME</v-col>
                  <v-col class="text-center">TYPE</v-col>
                  <v-col class="text-center">AMOUNT</v-col>
                  <v-col class="text-center d-none d-xl-flex d-lg-flex d-md-flex d-sm-flex">&nbsp;</v-col>
                </v-row>
              </v-sheet>
              <!-- <v-divider></v-divider> -->
              <v-sheet class="mb-2 pa-2 bet-history-bg-color mobile-padding-left-and-right" elavation="2"
                v-for="(item, rowIndex) in playerBets.rows" :key="`item-row-${rowIndex}`">
                <v-row class="d-flex align-center mobile-padding-left-and-right">
                  <v-col cols="4" class="text-center pa-0 line-height">{{ item._id }}</v-col>
                  <v-col class="text-center pa-0" v-if="showType">{{ item.betType | uppercase }}</v-col>
                  <v-col class="text-center mobile-padding-left-and-right pa-0">
                    <span :class="`${item.type == 'bet'
                      ? ''
                      : item.type == 'win' && item.amount > 0
                        ? 'green--text'
                        : item.type == 'cancelled' || (item.type == 'win' && item.amount <= 0)
                          ? 'red--text'
                          : ''
                      }`
                      ">{{ getType(item) }}</span>
                  </v-col>
                  <v-col class="text-center mobile-padding-left-and-right pa-0">{{
                    getAmount(item.amount, item.type) | currency("&#8369;", 2, { spaceBetweenAmountAndSymbol: true })
                  }}</v-col>
                  <v-col class="text-left pa-0" v-if="item.betType == 'flip'">
                    <v-btn x-small outlined color="orange" @click="viewCoinflip(item)"
                      v-if="item.type == 'win'">View</v-btn>
                  </v-col>
                  <v-col class="text-left pa-0" v-else>
                    <v-btn x-small outlined color="orange" link :href="`/wheel/${item.lobby.sequenceId}`"
                      v-if="item.status == 1 || item.status == 2">View</v-btn>
                  </v-col>
                </v-row>
              </v-sheet>

              <v-sheet class="mb-2 pa-2" elavation="2" v-if="playerBets.rows.length == 0">
                <v-row>
                  <v-col cols="12" class="text-center">No bets found.</v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>

        </v-card-text>
        <v-spacer></v-spacer>
        <div class="py-3 pr-1">
          <v-pagination v-model="meta.page" :total-visible="7" :length="Math.ceil(playerBets.count / meta.itemsPerPage)"
            circle @input="meta.page = $event" />
        </div>
        <!-- <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Close
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <view-previous-coinflip :dialog="showPreviewCoinflipDialog" @closeDialog="hideCoinflip" max-height="700px"
      max-width="700px" :coinflip="viewingCoinflip"></view-previous-coinflip>
  </div>
</template>

<script>
import ViewPreviousCoinflip from "./ViewPreviousCoinflip";
import { mapState } from "vuex";
export default {
  components: {
    ViewPreviousCoinflip,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      default: "100%",
    },
    maxHeight: {
      default: 100,
    },
    showType: {
      default: false,
    },
  },
  data() {
    return {
      showDialogHistory: false,
      dialogm1: "",
      showPreviewCoinflipDialog: false,
      viewingCoinflip: null,
      meta: {
        page: 1,
        itemsPerPage: 10,
      }
    };
  },
  computed: {
    ...mapState('player', ['playerBets']),
    ...mapState("auth", ["user"]),
  },
  watch: {
    dialog: function (newValue) {
      if (newValue) {
        console.log("NEW VALUE", newValue)
        this.showDialog();
      }
    },
    "meta.page": function () {
      this.getBetHistory();
    }
  },
  methods: {
    closeDialog: function () {
      this.showDialogHistory = false;
      this.$emit("closeDialog", true);
    },
    showDialog: function () {
      this.getBetHistory();
    },
    getBetHistory: async function () {
      const payload = {
        _id: this.user.id,
        betType: 'all',
        key: this.$route.params.key,
        meta: this.meta
      };
      await this.$store.dispatch('player/getPlayerBets', payload);
      this.showDialogHistory = true;
    },

    getAmount: function (amount, type) {
      if (type == "bet") {
        return -amount;
      }
      return amount;
    },

    getType: function (item) {
      if (item.type == "bet") {
        return "Bet";
      } else if (item.type == "win" && item.amount > 0) {
        return "Win";
      } else if (item.type == "win" && item.amount <= 0) {
        return "Lose";
      } else if (item.type == "cancelled") {
        return "Cancelled";
      }
    },

    viewCoinflip: async function (coinflip) {
      this.showPreviewCoinflipDialog = true;
      this.viewingCoinflip = await this.$store.dispatch("lobby/getLobbyById", coinflip.lobby._id);
    },

    hideCoinflip: function () {
      this.showPreviewCoinflipDialog = false;
      this.viewingCoinflip = null;
    },
  },
};
</script>
<style scoped>
.bet-history-title {
  background: linear-gradient(rgb(51, 200, 181) 0%, rgb(44, 171, 98) 100%);
  border-radius: 10px;
}

.bet-history-bg-color {
  background: #1b2221;
  border-radius: 16px;
}

.background-remove {
  background: none;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #333649 #16181f;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  left: 100px;
}

*::-webkit-scrollbar-track {
  background: #16181f;
}

*::-webkit-scrollbar-thumb {
  background-color: #333649;
  border-radius: 10px;
  border: 2px solid#16181f;
}

.line-height {
  line-height: .8rem;
}

@media screen and (max-width: 425px) {
  .mobile-padding-left-and-right {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 10.5px;
  }
}
</style>
